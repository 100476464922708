import { default as IndexO12CGG0PR0Meta } from "/app/pages/Analytics/Index.vue?macro=true";
import { default as Login0w7g6idff2Meta } from "/app/pages/Analytics/Login.vue?macro=true";
import { default as MostSoldOuEGRoRw50Meta } from "/app/pages/Analytics/MostSold.vue?macro=true";
import { default as Accesses0oZT9jKpcwMeta } from "/app/pages/Analytics/Partials/Accesses.vue?macro=true";
import { default as NewUsersNuCoY9c6GPMeta } from "/app/pages/Analytics/Partials/NewUsers.vue?macro=true";
import { default as PagesMostVisitedLluKquVrqAMeta } from "/app/pages/Analytics/Partials/PagesMostVisited.vue?macro=true";
import { default as ProductsMostSold4BGTOQGeJmMeta } from "/app/pages/Analytics/Partials/ProductsMostSold.vue?macro=true";
import { default as ProductsMostVisitedc4p7adepdzMeta } from "/app/pages/Analytics/Partials/ProductsMostVisited.vue?macro=true";
import { default as SalesBehaviourTLlRAgDqRRMeta } from "/app/pages/Analytics/Partials/SalesBehaviour.vue?macro=true";
import { default as Salesm7yfqmYYzKMeta } from "/app/pages/Analytics/Sales.vue?macro=true";
import { default as Visits5shN25WPAgMeta } from "/app/pages/Analytics/Visits.vue?macro=true";
import { default as ConfirmPasswordra4JnFDaTWMeta } from "/app/pages/Auth/ConfirmPassword.vue?macro=true";
import { default as ForgotPasswordCDue6KKurYMeta } from "/app/pages/Auth/ForgotPassword.vue?macro=true";
import { default as LoginekOdCTVoFyMeta } from "/app/pages/Auth/Login.vue?macro=true";
import { default as Registery2oeogxvmHMeta } from "/app/pages/Auth/Register.vue?macro=true";
import { default as _91token_93pnk3mLdG6mMeta } from "/app/pages/Auth/ResetPassword/[token].vue?macro=true";
import { default as ResetPasswordLAGYHoKH4rMeta } from "/app/pages/Auth/ResetPassword.vue?macro=true";
import { default as VerifyEmailErFhC3WKjYMeta } from "/app/pages/Auth/VerifyEmail.vue?macro=true";
import { default as editrTma1yDPsbMeta } from "/app/pages/Banners/[id]/edit.vue?macro=true";
import { default as createiobEvifhBOMeta } from "/app/pages/Banners/create.vue?macro=true";
import { default as indexOq7k0r7WgwMeta } from "/app/pages/Banners/index.vue?macro=true";
import { default as indexeED0W5vZuYMeta } from "/app/pages/Beneficiary/index.vue?macro=true";
import { default as indexSZk2qNn8VwMeta } from "/app/pages/Cart/index.vue?macro=true";
import { default as BenficiaryTableQaA6bEjzaMMeta } from "/app/pages/CartItems/BenficiaryTable.vue?macro=true";
import { default as CalculateItems2bvXTh9ugFMeta } from "/app/pages/CartItems/CalculateItems.vue?macro=true";
import { default as CartItemProductkvNTx8l92mMeta } from "/app/pages/CartItems/CartItemProduct.vue?macro=true";
import { default as CheckoutBSFzALE2fcMeta } from "/app/pages/CartItems/Checkout.vue?macro=true";
import { default as Completev0D28HZ17pMeta } from "/app/pages/CartItems/Complete.vue?macro=true";
import { default as indexmyJqRKmqKQMeta } from "/app/pages/CartItems/index.vue?macro=true";
import { default as Items0Oz7DwT43XMeta } from "/app/pages/CartItems/Items.vue?macro=true";
import { default as editkmVzMerEM4Meta } from "/app/pages/Categories/[category]/edit.vue?macro=true";
import { default as createhAeA2nmIX2Meta } from "/app/pages/Categories/create.vue?macro=true";
import { default as editB5xJYv9xQ3Meta } from "/app/pages/Categories/edit.vue?macro=true";
import { default as indexuVOdZoEgx5Meta } from "/app/pages/Categories/index.vue?macro=true";
import { default as editWXsQOKjQ9ZMeta } from "/app/pages/Coins/[id]/edit.vue?macro=true";
import { default as indexjwGWpPZEEFMeta } from "/app/pages/Coins/index.vue?macro=true";
import { default as showDoZv36MlQbMeta } from "/app/pages/Companies/[company]/show.vue?macro=true";
import { default as indexi7x2gV3qpRMeta } from "/app/pages/Companies/index.vue?macro=true";
import { default as indexS7GVjoY7GdMeta } from "/app/pages/Contact-Us/index.vue?macro=true";
import { default as indexgayIGsi6pbMeta } from "/app/pages/Distribution/index.vue?macro=true";
import { default as CreatenbylKJgQJRMeta } from "/app/pages/ExchangeRates/Create.vue?macro=true";
import { default as CreateBatchuYiDtqjk1fMeta } from "/app/pages/ExchangeRates/CreateBatch.vue?macro=true";
import { default as EditXlQb9IpFYxMeta } from "/app/pages/ExchangeRates/Edit.vue?macro=true";
import { default as index01agvEOGXXMeta } from "/app/pages/ExchangeRates/index.vue?macro=true";
import { default as Bannersx54ySNSot0Meta } from "/app/pages/Home/Banners.vue?macro=true";
import { default as DashboardMrCfxlVfSVMeta } from "/app/pages/Home/Dashboard.vue?macro=true";
import { default as FrequentAsksViewgVBFadoM5UMeta } from "/app/pages/Home/FrequentAsksView.vue?macro=true";
import { default as InformationPagedHwaFaiH5PMeta } from "/app/pages/Home/InformationPage.vue?macro=true";
import { default as OffersrQIIpt6B0yMeta } from "/app/pages/Home/Offers.vue?macro=true";
import { default as ShoppingCardInfotMJb8RYRPEMeta } from "/app/pages/Home/ShoppingCardInfo.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91InformationPage_93WZq69kjGVSMeta } from "/app/pages/information/[InformationPage].vue?macro=true";
import { default as BeneficiarypvHB0gCTERMeta } from "/app/pages/Invoices/Beneficiary.vue?macro=true";
import { default as CreateRXXjjVZkQvMeta } from "/app/pages/Invoices/Create.vue?macro=true";
import { default as indexzYSAMlQzM0Meta } from "/app/pages/Invoices/index.vue?macro=true";
import { default as ManualxmS50c65ObMeta } from "/app/pages/Invoices/Manual.vue?macro=true";
import { default as SaleBuyCardTVWkZiUuPlMeta } from "/app/pages/Invoices/SaleBuyCard.vue?macro=true";
import { default as editFc8zgetHvxMeta } from "/app/pages/Offers/[discount]/edit.vue?macro=true";
import { default as Create6kFFF7uUMIMeta } from "/app/pages/Offers/Create.vue?macro=true";
import { default as indexOPPBcA3NliMeta } from "/app/pages/Offers/index.vue?macro=true";
import { default as redirect_45page6WKUQZrer3Meta } from "/app/pages/Operation/[operation]/redirect-page.vue?macro=true";
import { default as showTor6azEp7UMeta } from "/app/pages/Operation/[operation]/show.vue?macro=true";
import { default as success_45pagegE11xjY6oKMeta } from "/app/pages/Operation/[operation]/success-page.vue?macro=true";
import { default as indexJc87iVqL2EMeta } from "/app/pages/Operation/index.vue?macro=true";
import { default as redirect_45pageXQvaacDffdMeta } from "/app/pages/Orders/[order]/redirect-page.vue?macro=true";
import { default as showG9p1PawY1uMeta } from "/app/pages/Orders/[order]/show.vue?macro=true";
import { default as success_45pageKccXviSTeTMeta } from "/app/pages/Orders/[order]/success-page.vue?macro=true";
import { default as Create5R6EFUKi7cMeta } from "/app/pages/Orders/Create.vue?macro=true";
import { default as DetailsShipmentYZ3WMG6yLSMeta } from "/app/pages/Orders/DetailsShipment.vue?macro=true";
import { default as EditZPfmbZm0THMeta } from "/app/pages/Orders/Edit.vue?macro=true";
import { default as indexh5pv0imasEMeta } from "/app/pages/Orders/index.vue?macro=true";
import { default as ownerOrderYRW14K0Z3PMeta } from "/app/pages/Orders/ownerOrder.vue?macro=true";
import { default as editXDZIlXwaDtMeta } from "/app/pages/OrderStatuses/[status]/edit.vue?macro=true";
import { default as CreateYpjGfevGp1Meta } from "/app/pages/OrderStatuses/Create.vue?macro=true";
import { default as indexUTTXfYPU7xMeta } from "/app/pages/OrderStatuses/index.vue?macro=true";
import { default as editvWT6LMJ74rMeta } from "/app/pages/PointOfSales/[id]/edit.vue?macro=true";
import { default as CreatehTTv9f1PVfMeta } from "/app/pages/PointOfSales/Create.vue?macro=true";
import { default as indexBTXBkohy0rMeta } from "/app/pages/PointOfSales/index.vue?macro=true";
import { default as _91product_93fyC6SDu9TAMeta } from "/app/pages/Products/[product].vue?macro=true";
import { default as _91Products_93IaJvg59iQ3Meta } from "/app/pages/Products/[Products].vue?macro=true";
import { default as editJpj8E8UponMeta } from "/app/pages/Products/[slug]/edit.vue?macro=true";
import { default as AcceptReviewCvyZQh6en6Meta } from "/app/pages/Products/AcceptReview.vue?macro=true";
import { default as _91bookable_93BABuWzdSNSMeta } from "/app/pages/Products/bookable/[bookable].vue?macro=true";
import { default as CreateDQA0mG28MSMeta } from "/app/pages/Products/Create.vue?macro=true";
import { default as FileRFL8AO6NOPMeta } from "/app/pages/Products/File.vue?macro=true";
import { default as ImagessVyoD3fYM0Meta } from "/app/pages/Products/Images.vue?macro=true";
import { default as indexTexopOjvruMeta } from "/app/pages/Products/index.vue?macro=true";
import { default as ReadReviewy71luuBhJBMeta } from "/app/pages/Products/ReadReview.vue?macro=true";
import { default as _91product_93OmygPy1iPjMeta } from "/app/pages/Products/Review/Read/[product].vue?macro=true";
import { default as _91product_937uvvlCEDhtMeta } from "/app/pages/Products/Review/Write/[product].vue?macro=true";
import { default as WriteReviewgs8TomAyX5Meta } from "/app/pages/Products/WriteReview.vue?macro=true";
import { default as BeneficiarieslFkkMC914SMeta } from "/app/pages/Profile/Beneficiaries.vue?macro=true";
import { default as Edit1Pmdv7HLiAMeta } from "/app/pages/Profile/Edit.vue?macro=true";
import { default as BeneficiaryOfUserkNSrzT2TQgMeta } from "/app/pages/Profile/Partials/BeneficiaryOfUser.vue?macro=true";
import { default as DeleteUserFormNtebD0V36DMeta } from "/app/pages/Profile/Partials/DeleteUserForm.vue?macro=true";
import { default as SecondBeneficiaryOfUserVyS9Jujp5LMeta } from "/app/pages/Profile/Partials/SecondBeneficiaryOfUser.vue?macro=true";
import { default as UpdatePasswordFormsEtmYX7YtKMeta } from "/app/pages/Profile/Partials/UpdatePasswordForm.vue?macro=true";
import { default as UpdateProfileInformationForm4taEjdG2e8Meta } from "/app/pages/Profile/Partials/UpdateProfileInformationForm.vue?macro=true";
import { default as indexY1r5ZCLP1uMeta } from "/app/pages/Reports/inventory/index.vue?macro=true";
import { default as indexGpMLTd5yaEMeta } from "/app/pages/Reports/movements/index.vue?macro=true";
import { default as OrdersOaWgnTR9iFMeta } from "/app/pages/Reports/Orders.vue?macro=true";
import { default as indexVJviPnHryMMeta } from "/app/pages/Reports/sale/index.vue?macro=true";
import { default as SalesForAccountingwbtHgFRsUvMeta } from "/app/pages/Reports/SalesForAccounting.vue?macro=true";
import { default as ShoppingCardlal5ZFDwcgMeta } from "/app/pages/Reports/ShoppingCard.vue?macro=true";
import { default as indexWs7qxqHkN5Meta } from "/app/pages/Reports/soldProduct/index.vue?macro=true";
import { default as show2HTkUtm0wOMeta } from "/app/pages/Reservations/[reservation]/show.vue?macro=true";
import { default as indexirW8ofOVsXMeta } from "/app/pages/Reservations/index.vue?macro=true";
import { default as ownerReservationcF3sggu4hAMeta } from "/app/pages/Reservations/ownerReservation.vue?macro=true";
import { default as ShowDl6XkEZP79Meta } from "/app/pages/Reservations/Show.vue?macro=true";
import { default as editomVgkOVRWcMeta } from "/app/pages/Roles/[role]/edit.vue?macro=true";
import { default as createVnTSAH1we6Meta } from "/app/pages/Roles/create.vue?macro=true";
import { default as editUKnfRyiShVMeta } from "/app/pages/Roles/edit.vue?macro=true";
import { default as indexG2oqmW6SLrMeta } from "/app/pages/Roles/index.vue?macro=true";
import { default as CustomizationkASzQwONY7Meta } from "/app/pages/Settings/Customization.vue?macro=true";
import { default as GeneralsrpjGAt59PrMeta } from "/app/pages/Settings/Generals.vue?macro=true";
import { default as ContactszSZg6GiDTfMeta } from "/app/pages/Settings/Partials/Contacts.vue?macro=true";
import { default as FrequentAsksBn2zKi8IkHMeta } from "/app/pages/Settings/Partials/FrequentAsks.vue?macro=true";
import { default as InformationSectionV187fhf9dOMeta } from "/app/pages/Settings/Partials/InformationSection.vue?macro=true";
import { default as LocalPickupVsRKGmMYEjMeta } from "/app/pages/Settings/Partials/LocalPickup.vue?macro=true";
import { default as Misczdi2gP86fpMeta } from "/app/pages/Settings/Partials/Misc.vue?macro=true";
import { default as MiscellaneouscLtLp55eJDMeta } from "/app/pages/Settings/Partials/Miscellaneous.vue?macro=true";
import { default as Notificationsnb379uGkMaMeta } from "/app/pages/Settings/Partials/Notifications.vue?macro=true";
import { default as PayFormItemiuF481fukmMeta } from "/app/pages/Settings/Partials/PayFormItem.vue?macro=true";
import { default as PayFormsdZZuNlPNW5Meta } from "/app/pages/Settings/Partials/PayForms.vue?macro=true";
import { default as ProductsInventarylsHQ6u2bUHMeta } from "/app/pages/Settings/Partials/ProductsInventary.vue?macro=true";
import { default as RelatedProductStrategiesyN8UW6oqJlMeta } from "/app/pages/Settings/Partials/RelatedProductStrategies.vue?macro=true";
import { default as ScheduleFormr0eCz4D4vuMeta } from "/app/pages/Settings/Partials/ScheduleForm.vue?macro=true";
import { default as ScheduleStore8W1GrjRhpGMeta } from "/app/pages/Settings/Partials/ScheduleStore.vue?macro=true";
import { default as Shipmentw60MzqfWInMeta } from "/app/pages/Settings/Partials/Shipment.vue?macro=true";
import { default as ShipmentProvincelalTbme5ofMeta } from "/app/pages/Settings/Partials/ShipmentProvince.vue?macro=true";
import { default as ShipmentUnitRangeK5EBiz2lbbMeta } from "/app/pages/Settings/Partials/ShipmentUnitRange.vue?macro=true";
import { default as ShoppingCardQmuO3fAn8OMeta } from "/app/pages/Settings/Partials/ShoppingCard.vue?macro=true";
import { default as TwiceTimeN5zK7Uf690Meta } from "/app/pages/Settings/Partials/TwiceTime.vue?macro=true";
import { default as indexoGVG8olVvFMeta } from "/app/pages/Store/index.vue?macro=true";
import { default as edit3iyWcfzSKzMeta } from "/app/pages/Users/[user]/edit.vue?macro=true";
import { default as Create5o0fwfxU4HMeta } from "/app/pages/Users/Create.vue?macro=true";
import { default as indexxWYkURhnP1Meta } from "/app/pages/Users/index.vue?macro=true";
export default [
  {
    name: "Analytics-Index",
    path: "/Analytics/Index",
    component: () => import("/app/pages/Analytics/Index.vue")
  },
  {
    name: "Analytics-Login",
    path: "/Analytics/Login",
    component: () => import("/app/pages/Analytics/Login.vue")
  },
  {
    name: "Analytics-MostSold",
    path: "/Analytics/MostSold",
    component: () => import("/app/pages/Analytics/MostSold.vue")
  },
  {
    name: "Analytics-Partials-Accesses",
    path: "/Analytics/Partials/Accesses",
    component: () => import("/app/pages/Analytics/Partials/Accesses.vue")
  },
  {
    name: "Analytics-Partials-NewUsers",
    path: "/Analytics/Partials/NewUsers",
    component: () => import("/app/pages/Analytics/Partials/NewUsers.vue")
  },
  {
    name: "Analytics-Partials-PagesMostVisited",
    path: "/Analytics/Partials/PagesMostVisited",
    component: () => import("/app/pages/Analytics/Partials/PagesMostVisited.vue")
  },
  {
    name: "Analytics-Partials-ProductsMostSold",
    path: "/Analytics/Partials/ProductsMostSold",
    component: () => import("/app/pages/Analytics/Partials/ProductsMostSold.vue")
  },
  {
    name: "Analytics-Partials-ProductsMostVisited",
    path: "/Analytics/Partials/ProductsMostVisited",
    component: () => import("/app/pages/Analytics/Partials/ProductsMostVisited.vue")
  },
  {
    name: "Analytics-Partials-SalesBehaviour",
    path: "/Analytics/Partials/SalesBehaviour",
    component: () => import("/app/pages/Analytics/Partials/SalesBehaviour.vue")
  },
  {
    name: "Analytics-Sales",
    path: "/Analytics/Sales",
    component: () => import("/app/pages/Analytics/Sales.vue")
  },
  {
    name: "Analytics-Visits",
    path: "/Analytics/Visits",
    component: () => import("/app/pages/Analytics/Visits.vue")
  },
  {
    name: "Auth-ConfirmPassword",
    path: "/Auth/ConfirmPassword",
    component: () => import("/app/pages/Auth/ConfirmPassword.vue")
  },
  {
    name: "Auth-ForgotPassword",
    path: "/Auth/ForgotPassword",
    meta: ForgotPasswordCDue6KKurYMeta || {},
    component: () => import("/app/pages/Auth/ForgotPassword.vue")
  },
  {
    name: "Auth-Login",
    path: "/Auth/Login",
    meta: LoginekOdCTVoFyMeta || {},
    component: () => import("/app/pages/Auth/Login.vue")
  },
  {
    name: "Auth-Register",
    path: "/Auth/Register",
    component: () => import("/app/pages/Auth/Register.vue")
  },
  {
    name: "Auth-ResetPassword",
    path: "/Auth/ResetPassword",
    meta: ResetPasswordLAGYHoKH4rMeta || {},
    component: () => import("/app/pages/Auth/ResetPassword.vue"),
    children: [
  {
    name: "Auth-ResetPassword-token",
    path: ":token()",
    component: () => import("/app/pages/Auth/ResetPassword/[token].vue")
  }
]
  },
  {
    name: "Auth-VerifyEmail",
    path: "/Auth/VerifyEmail",
    component: () => import("/app/pages/Auth/VerifyEmail.vue")
  },
  {
    name: "Banners-id-edit",
    path: "/Banners/:id()/edit",
    meta: editrTma1yDPsbMeta || {},
    component: () => import("/app/pages/Banners/[id]/edit.vue")
  },
  {
    name: "Banners-create",
    path: "/Banners/create",
    component: () => import("/app/pages/Banners/create.vue")
  },
  {
    name: "Banners",
    path: "/Banners",
    meta: indexOq7k0r7WgwMeta || {},
    component: () => import("/app/pages/Banners/index.vue")
  },
  {
    name: "Beneficiary",
    path: "/Beneficiary",
    meta: indexeED0W5vZuYMeta || {},
    component: () => import("/app/pages/Beneficiary/index.vue")
  },
  {
    name: "Cart",
    path: "/Cart",
    meta: indexSZk2qNn8VwMeta || {},
    component: () => import("/app/pages/Cart/index.vue")
  },
  {
    name: "CartItems-BenficiaryTable",
    path: "/CartItems/BenficiaryTable",
    component: () => import("/app/pages/CartItems/BenficiaryTable.vue")
  },
  {
    name: "CartItems-CalculateItems",
    path: "/CartItems/CalculateItems",
    component: () => import("/app/pages/CartItems/CalculateItems.vue")
  },
  {
    name: "CartItems-CartItemProduct",
    path: "/CartItems/CartItemProduct",
    component: () => import("/app/pages/CartItems/CartItemProduct.vue")
  },
  {
    name: "CartItems-Checkout",
    path: "/CartItems/Checkout",
    component: () => import("/app/pages/CartItems/Checkout.vue")
  },
  {
    name: "CartItems-Complete",
    path: "/CartItems/Complete",
    component: () => import("/app/pages/CartItems/Complete.vue")
  },
  {
    name: "CartItems",
    path: "/CartItems",
    component: () => import("/app/pages/CartItems/index.vue")
  },
  {
    name: "CartItems-Items",
    path: "/CartItems/Items",
    component: () => import("/app/pages/CartItems/Items.vue")
  },
  {
    name: "Categories-category-edit",
    path: "/Categories/:category()/edit",
    component: () => import("/app/pages/Categories/[category]/edit.vue")
  },
  {
    name: "Categories-create",
    path: "/Categories/create",
    component: () => import("/app/pages/Categories/create.vue")
  },
  {
    name: "Categories-edit",
    path: "/Categories/edit",
    component: () => import("/app/pages/Categories/edit.vue")
  },
  {
    name: "Categories",
    path: "/Categories",
    meta: indexuVOdZoEgx5Meta || {},
    component: () => import("/app/pages/Categories/index.vue")
  },
  {
    name: "Coins-id-edit",
    path: "/Coins/:id()/edit",
    meta: editWXsQOKjQ9ZMeta || {},
    component: () => import("/app/pages/Coins/[id]/edit.vue")
  },
  {
    name: "Coins",
    path: "/Coins",
    meta: indexjwGWpPZEEFMeta || {},
    component: () => import("/app/pages/Coins/index.vue")
  },
  {
    name: "Companies-company-show",
    path: "/Companies/:company()/show",
    component: () => import("/app/pages/Companies/[company]/show.vue")
  },
  {
    name: "Companies",
    path: "/Companies",
    meta: indexi7x2gV3qpRMeta || {},
    component: () => import("/app/pages/Companies/index.vue")
  },
  {
    name: "Contact-Us",
    path: "/Contact-Us",
    component: () => import("/app/pages/Contact-Us/index.vue")
  },
  {
    name: "Distribution",
    path: "/Distribution",
    component: () => import("/app/pages/Distribution/index.vue")
  },
  {
    name: "ExchangeRates-Create",
    path: "/ExchangeRates/Create",
    component: () => import("/app/pages/ExchangeRates/Create.vue")
  },
  {
    name: "ExchangeRates-CreateBatch",
    path: "/ExchangeRates/CreateBatch",
    component: () => import("/app/pages/ExchangeRates/CreateBatch.vue")
  },
  {
    name: "ExchangeRates-Edit",
    path: "/ExchangeRates/Edit",
    component: () => import("/app/pages/ExchangeRates/Edit.vue")
  },
  {
    name: "ExchangeRates",
    path: "/ExchangeRates",
    component: () => import("/app/pages/ExchangeRates/index.vue")
  },
  {
    name: "Home-Banners",
    path: "/Home/Banners",
    component: () => import("/app/pages/Home/Banners.vue")
  },
  {
    name: "Home-Dashboard",
    path: "/Home/Dashboard",
    component: () => import("/app/pages/Home/Dashboard.vue")
  },
  {
    name: "Home-FrequentAsksView",
    path: "/Home/FrequentAsksView",
    component: () => import("/app/pages/Home/FrequentAsksView.vue")
  },
  {
    name: "Home-InformationPage",
    path: "/Home/InformationPage",
    component: () => import("/app/pages/Home/InformationPage.vue")
  },
  {
    name: "Home-Offers",
    path: "/Home/Offers",
    component: () => import("/app/pages/Home/Offers.vue")
  },
  {
    name: "Home-ShoppingCardInfo",
    path: "/Home/ShoppingCardInfo",
    component: () => import("/app/pages/Home/ShoppingCardInfo.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "information-InformationPage",
    path: "/information/:InformationPage()",
    component: () => import("/app/pages/information/[InformationPage].vue")
  },
  {
    name: "Invoices-Beneficiary",
    path: "/Invoices/Beneficiary",
    component: () => import("/app/pages/Invoices/Beneficiary.vue")
  },
  {
    name: "Invoices-Create",
    path: "/Invoices/Create",
    component: () => import("/app/pages/Invoices/Create.vue")
  },
  {
    name: "Invoices",
    path: "/Invoices",
    meta: indexzYSAMlQzM0Meta || {},
    component: () => import("/app/pages/Invoices/index.vue")
  },
  {
    name: "Invoices-Manual",
    path: "/Invoices/Manual",
    component: () => import("/app/pages/Invoices/Manual.vue")
  },
  {
    name: "Invoices-SaleBuyCard",
    path: "/Invoices/SaleBuyCard",
    component: () => import("/app/pages/Invoices/SaleBuyCard.vue")
  },
  {
    name: "Offers-discount-edit",
    path: "/Offers/:discount()/edit",
    component: () => import("/app/pages/Offers/[discount]/edit.vue")
  },
  {
    name: "Offers-Create",
    path: "/Offers/Create",
    component: () => import("/app/pages/Offers/Create.vue")
  },
  {
    name: "Offers",
    path: "/Offers",
    component: () => import("/app/pages/Offers/index.vue")
  },
  {
    name: "Operation-operation-redirect-page",
    path: "/Operation/:operation()/redirect-page",
    component: () => import("/app/pages/Operation/[operation]/redirect-page.vue")
  },
  {
    name: "Operation-operation-show",
    path: "/Operation/:operation()/show",
    meta: showTor6azEp7UMeta || {},
    component: () => import("/app/pages/Operation/[operation]/show.vue")
  },
  {
    name: "Operation-operation-success-page",
    path: "/Operation/:operation()/success-page",
    component: () => import("/app/pages/Operation/[operation]/success-page.vue")
  },
  {
    name: "Operation",
    path: "/Operation",
    meta: indexJc87iVqL2EMeta || {},
    component: () => import("/app/pages/Operation/index.vue")
  },
  {
    name: "Orders-order-redirect-page",
    path: "/Orders/:order()/redirect-page",
    component: () => import("/app/pages/Orders/[order]/redirect-page.vue")
  },
  {
    name: "Orders-order-show",
    path: "/Orders/:order()/show",
    meta: showG9p1PawY1uMeta || {},
    component: () => import("/app/pages/Orders/[order]/show.vue")
  },
  {
    name: "Orders-order-success-page",
    path: "/Orders/:order()/success-page",
    component: () => import("/app/pages/Orders/[order]/success-page.vue")
  },
  {
    name: "Orders-Create",
    path: "/Orders/Create",
    component: () => import("/app/pages/Orders/Create.vue")
  },
  {
    name: "Orders-DetailsShipment",
    path: "/Orders/DetailsShipment",
    component: () => import("/app/pages/Orders/DetailsShipment.vue")
  },
  {
    name: "Orders-Edit",
    path: "/Orders/Edit",
    component: () => import("/app/pages/Orders/Edit.vue")
  },
  {
    name: "Orders",
    path: "/Orders",
    meta: indexh5pv0imasEMeta || {},
    component: () => import("/app/pages/Orders/index.vue")
  },
  {
    name: "Orders-ownerOrder",
    path: "/Orders/ownerOrder",
    meta: ownerOrderYRW14K0Z3PMeta || {},
    component: () => import("/app/pages/Orders/ownerOrder.vue")
  },
  {
    name: "OrderStatuses-status-edit",
    path: "/OrderStatuses/:status()/edit",
    component: () => import("/app/pages/OrderStatuses/[status]/edit.vue")
  },
  {
    name: "OrderStatuses-Create",
    path: "/OrderStatuses/Create",
    component: () => import("/app/pages/OrderStatuses/Create.vue")
  },
  {
    name: "OrderStatuses",
    path: "/OrderStatuses",
    meta: indexUTTXfYPU7xMeta || {},
    component: () => import("/app/pages/OrderStatuses/index.vue")
  },
  {
    name: "PointOfSales-id-edit",
    path: "/PointOfSales/:id()/edit",
    meta: editvWT6LMJ74rMeta || {},
    component: () => import("/app/pages/PointOfSales/[id]/edit.vue")
  },
  {
    name: "PointOfSales-Create",
    path: "/PointOfSales/Create",
    component: () => import("/app/pages/PointOfSales/Create.vue")
  },
  {
    name: "PointOfSales",
    path: "/PointOfSales",
    meta: indexBTXBkohy0rMeta || {},
    component: () => import("/app/pages/PointOfSales/index.vue")
  },
  {
    name: "Products-product",
    path: "/Products/:product()",
    meta: _91product_93fyC6SDu9TAMeta || {},
    component: () => import("/app/pages/Products/[product].vue")
  },
  {
    name: "Products-Products",
    path: "/Products/:Products()",
    component: () => import("/app/pages/Products/[Products].vue")
  },
  {
    name: "Products-slug-edit",
    path: "/Products/:slug()/edit",
    meta: editJpj8E8UponMeta || {},
    component: () => import("/app/pages/Products/[slug]/edit.vue")
  },
  {
    name: "Products-AcceptReview",
    path: "/Products/AcceptReview",
    component: () => import("/app/pages/Products/AcceptReview.vue")
  },
  {
    name: "Products-bookable-bookable",
    path: "/Products/bookable/:bookable()",
    meta: _91bookable_93BABuWzdSNSMeta || {},
    component: () => import("/app/pages/Products/bookable/[bookable].vue")
  },
  {
    name: "Products-Create",
    path: "/Products/Create",
    meta: CreateDQA0mG28MSMeta || {},
    component: () => import("/app/pages/Products/Create.vue")
  },
  {
    name: "Products-File",
    path: "/Products/File",
    component: () => import("/app/pages/Products/File.vue")
  },
  {
    name: "Products-Images",
    path: "/Products/Images",
    component: () => import("/app/pages/Products/Images.vue")
  },
  {
    name: "Products",
    path: "/Products",
    meta: indexTexopOjvruMeta || {},
    component: () => import("/app/pages/Products/index.vue")
  },
  {
    name: "Products-ReadReview",
    path: "/Products/ReadReview",
    component: () => import("/app/pages/Products/ReadReview.vue")
  },
  {
    name: "Products-Review-Read-product",
    path: "/Products/Review/Read/:product()",
    component: () => import("/app/pages/Products/Review/Read/[product].vue")
  },
  {
    name: "Products-Review-Write-product",
    path: "/Products/Review/Write/:product()",
    component: () => import("/app/pages/Products/Review/Write/[product].vue")
  },
  {
    name: "Products-WriteReview",
    path: "/Products/WriteReview",
    component: () => import("/app/pages/Products/WriteReview.vue")
  },
  {
    name: "Profile-Beneficiaries",
    path: "/Profile/Beneficiaries",
    component: () => import("/app/pages/Profile/Beneficiaries.vue")
  },
  {
    name: "Profile-Edit",
    path: "/Profile/Edit",
    component: () => import("/app/pages/Profile/Edit.vue")
  },
  {
    name: "Profile-Partials-BeneficiaryOfUser",
    path: "/Profile/Partials/BeneficiaryOfUser",
    component: () => import("/app/pages/Profile/Partials/BeneficiaryOfUser.vue")
  },
  {
    name: "Profile-Partials-DeleteUserForm",
    path: "/Profile/Partials/DeleteUserForm",
    component: () => import("/app/pages/Profile/Partials/DeleteUserForm.vue")
  },
  {
    name: "Profile-Partials-SecondBeneficiaryOfUser",
    path: "/Profile/Partials/SecondBeneficiaryOfUser",
    component: () => import("/app/pages/Profile/Partials/SecondBeneficiaryOfUser.vue")
  },
  {
    name: "Profile-Partials-UpdatePasswordForm",
    path: "/Profile/Partials/UpdatePasswordForm",
    component: () => import("/app/pages/Profile/Partials/UpdatePasswordForm.vue")
  },
  {
    name: "Profile-Partials-UpdateProfileInformationForm",
    path: "/Profile/Partials/UpdateProfileInformationForm",
    component: () => import("/app/pages/Profile/Partials/UpdateProfileInformationForm.vue")
  },
  {
    name: "Reports-inventory",
    path: "/Reports/inventory",
    component: () => import("/app/pages/Reports/inventory/index.vue")
  },
  {
    name: "Reports-movements",
    path: "/Reports/movements",
    component: () => import("/app/pages/Reports/movements/index.vue")
  },
  {
    name: "Reports-Orders",
    path: "/Reports/Orders",
    component: () => import("/app/pages/Reports/Orders.vue")
  },
  {
    name: "Reports-sale",
    path: "/Reports/sale",
    component: () => import("/app/pages/Reports/sale/index.vue")
  },
  {
    name: "Reports-SalesForAccounting",
    path: "/Reports/SalesForAccounting",
    component: () => import("/app/pages/Reports/SalesForAccounting.vue")
  },
  {
    name: "Reports-ShoppingCard",
    path: "/Reports/ShoppingCard",
    component: () => import("/app/pages/Reports/ShoppingCard.vue")
  },
  {
    name: "Reports-soldProduct",
    path: "/Reports/soldProduct",
    component: () => import("/app/pages/Reports/soldProduct/index.vue")
  },
  {
    name: "Reservations-reservation-show",
    path: "/Reservations/:reservation()/show",
    meta: show2HTkUtm0wOMeta || {},
    component: () => import("/app/pages/Reservations/[reservation]/show.vue")
  },
  {
    name: "Reservations",
    path: "/Reservations",
    meta: indexirW8ofOVsXMeta || {},
    component: () => import("/app/pages/Reservations/index.vue")
  },
  {
    name: "Reservations-ownerReservation",
    path: "/Reservations/ownerReservation",
    meta: ownerReservationcF3sggu4hAMeta || {},
    component: () => import("/app/pages/Reservations/ownerReservation.vue")
  },
  {
    name: "Reservations-Show",
    path: "/Reservations/Show",
    component: () => import("/app/pages/Reservations/Show.vue")
  },
  {
    name: "Roles-role-edit",
    path: "/Roles/:role()/edit",
    component: () => import("/app/pages/Roles/[role]/edit.vue")
  },
  {
    name: "Roles-create",
    path: "/Roles/create",
    component: () => import("/app/pages/Roles/create.vue")
  },
  {
    name: "Roles-edit",
    path: "/Roles/edit",
    component: () => import("/app/pages/Roles/edit.vue")
  },
  {
    name: "Roles",
    path: "/Roles",
    meta: indexG2oqmW6SLrMeta || {},
    component: () => import("/app/pages/Roles/index.vue")
  },
  {
    name: "Settings-Customization",
    path: "/Settings/Customization",
    meta: CustomizationkASzQwONY7Meta || {},
    component: () => import("/app/pages/Settings/Customization.vue")
  },
  {
    name: "Settings-Generals",
    path: "/Settings/Generals",
    meta: GeneralsrpjGAt59PrMeta || {},
    component: () => import("/app/pages/Settings/Generals.vue")
  },
  {
    name: "Settings-Partials-Contacts",
    path: "/Settings/Partials/Contacts",
    component: () => import("/app/pages/Settings/Partials/Contacts.vue")
  },
  {
    name: "Settings-Partials-FrequentAsks",
    path: "/Settings/Partials/FrequentAsks",
    component: () => import("/app/pages/Settings/Partials/FrequentAsks.vue")
  },
  {
    name: "Settings-Partials-InformationSection",
    path: "/Settings/Partials/InformationSection",
    component: () => import("/app/pages/Settings/Partials/InformationSection.vue")
  },
  {
    name: "Settings-Partials-LocalPickup",
    path: "/Settings/Partials/LocalPickup",
    component: () => import("/app/pages/Settings/Partials/LocalPickup.vue")
  },
  {
    name: "Settings-Partials-Misc",
    path: "/Settings/Partials/Misc",
    component: () => import("/app/pages/Settings/Partials/Misc.vue")
  },
  {
    name: "Settings-Partials-Miscellaneous",
    path: "/Settings/Partials/Miscellaneous",
    component: () => import("/app/pages/Settings/Partials/Miscellaneous.vue")
  },
  {
    name: "Settings-Partials-Notifications",
    path: "/Settings/Partials/Notifications",
    component: () => import("/app/pages/Settings/Partials/Notifications.vue")
  },
  {
    name: "Settings-Partials-PayFormItem",
    path: "/Settings/Partials/PayFormItem",
    component: () => import("/app/pages/Settings/Partials/PayFormItem.vue")
  },
  {
    name: "Settings-Partials-PayForms",
    path: "/Settings/Partials/PayForms",
    component: () => import("/app/pages/Settings/Partials/PayForms.vue")
  },
  {
    name: "Settings-Partials-ProductsInventary",
    path: "/Settings/Partials/ProductsInventary",
    component: () => import("/app/pages/Settings/Partials/ProductsInventary.vue")
  },
  {
    name: "Settings-Partials-RelatedProductStrategies",
    path: "/Settings/Partials/RelatedProductStrategies",
    component: () => import("/app/pages/Settings/Partials/RelatedProductStrategies.vue")
  },
  {
    name: "Settings-Partials-ScheduleForm",
    path: "/Settings/Partials/ScheduleForm",
    component: () => import("/app/pages/Settings/Partials/ScheduleForm.vue")
  },
  {
    name: "Settings-Partials-ScheduleStore",
    path: "/Settings/Partials/ScheduleStore",
    component: () => import("/app/pages/Settings/Partials/ScheduleStore.vue")
  },
  {
    name: "Settings-Partials-Shipment",
    path: "/Settings/Partials/Shipment",
    component: () => import("/app/pages/Settings/Partials/Shipment.vue")
  },
  {
    name: "Settings-Partials-ShipmentProvince",
    path: "/Settings/Partials/ShipmentProvince",
    component: () => import("/app/pages/Settings/Partials/ShipmentProvince.vue")
  },
  {
    name: "Settings-Partials-ShipmentUnitRange",
    path: "/Settings/Partials/ShipmentUnitRange",
    component: () => import("/app/pages/Settings/Partials/ShipmentUnitRange.vue")
  },
  {
    name: "Settings-Partials-ShoppingCard",
    path: "/Settings/Partials/ShoppingCard",
    component: () => import("/app/pages/Settings/Partials/ShoppingCard.vue")
  },
  {
    name: "Settings-Partials-TwiceTime",
    path: "/Settings/Partials/TwiceTime",
    component: () => import("/app/pages/Settings/Partials/TwiceTime.vue")
  },
  {
    name: "Store",
    path: "/Store",
    component: () => import("/app/pages/Store/index.vue")
  },
  {
    name: "Users-user-edit",
    path: "/Users/:user()/edit",
    component: () => import("/app/pages/Users/[user]/edit.vue")
  },
  {
    name: "Users-Create",
    path: "/Users/Create",
    component: () => import("/app/pages/Users/Create.vue")
  },
  {
    name: "Users",
    path: "/Users",
    meta: indexxWYkURhnP1Meta || {},
    component: () => import("/app/pages/Users/index.vue")
  }
]