import { onMounted } from 'vue';
<script setup lang="ts">
const {t,locale,availableLocales, setLocale} = useI18n()

const languageStore = useLanguageStore()
 const languageStoreData = storeToRefs(languageStore)

 onMounted(()=>{
  setLocale(languageStoreData.language.value)
 })

</script>

<template>
  <div>
    <NuxtLayout >
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
